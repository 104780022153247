
































import { v4 as uuidv4 } from 'uuid';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import TooltipSelect from '../tooltip/TooltipSelect.vue';
import UiFormLabel from './UiFormLabel.vue';

export interface RadioOptions {
  label: string;
  value: unknown;
}

@Component({ components: { UiFormLabel, TooltipSelect } })
export default class UiRadioGroup extends Vue {
  @Prop({ default: 'horizontal' })
  public readonly layout!: 'horizontal' | 'vertical';

  @Prop()
  public readonly label?: string;

  @Prop()
  public readonly description?: string;

  @Prop({ required: true })
  public readonly values!: (string | RadioOptions)[] | Record<string, string>;

  @Prop()
  public readonly value?: string;

  @Prop()
  public readonly disabled?: boolean;

  @Prop({ default: null })
  public readonly tip?: string;

  public instanceId = uuidv4();
  public selectedIdx: number | null = null;
  private inputEventLock = false;

  public get normalValues(): RadioOptions[] {
    return Array.isArray(this.values)
      ? this.values.map((x) => (typeof x === 'string' ? { value: x, label: x } : x))
      : Object.entries(this.values).map(([label, value]) => ({ label, value }));
  }

  @Watch('value', { immediate: true })
  private onValueChange(newValue: unknown) {
    const index = this.valueToIndexes.get(newValue);
    if (index !== undefined) {
      this.selectedIdx = index;
    }
  }

  @Watch('selectedIdx')
  private onSelectedIdxChange(newValue: number | null) {
    this.$emit('input', newValue !== null ? this.normalValues[newValue].value : undefined);
  }

  public get valueToIndexes(): Map<unknown, number> {
    return this.normalValues.reduce((a, x, i) => {
      a.set(x.value, i);
      return a;
    }, new Map<unknown, number>());
  }
}
