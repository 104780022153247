var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-text-gray-600 tw-font-sans",staticStyle:{"font-size":"16px"}},[_c('div',{staticClass:"tw-text-gray-600 tw-font-sans",staticStyle:{"font-size":"16px"}},[_c('div',{staticClass:"tw-border-b tw-border-gray-300"},[_c('div',{staticClass:"tw-flex tw-items-center tw-justify-between tw-px-6 tw-py-5"},[_c('h3',{staticClass:"tw-text-2xl tw-capitalize tw-font-medium"},[_vm._v("Transforms")]),_c('run-job',{on:{"refresh":_vm.refresh}})],1)])]),_c('div',{staticClass:"tw-w-full tw-border-b tw-border-gray-300",staticStyle:{"background":"#fafafa"}},[_c('div',{staticClass:"tw-pl-4 sm:tw-pl-6 lg:tw-pl-8 tw-text-gray-800 tw-flex tw-items-center tw-justify-between tw-pt-1 tw-mb-1"},[_c('div',{staticClass:"tw-flex tw-items-center"},[_c('fa',{staticClass:"tw-h-4 tw-w-5 tw-py-0.5 tw-mr-4 tw-text-neutral-500 tw-text-primary-300 tw-cursor-pointer",attrs:{"icon":"fa-solid fa-arrow-rotate-right"},on:{"click":_vm.refresh}})],1),_c('div',{staticClass:"tw-mr-3 tw-flex tw-items-center"},[_c('span',{staticClass:"tw-whitespace-nowrap tw-text-xs tw-text-neutral-300 tw-mr-1"},[_vm._v("Page size ")]),_c('ui-select',{staticClass:"tw-mr-10",attrs:{"values":['10', '20', '50', '100']},model:{value:(_vm.limit),callback:function ($$v) {_vm.limit=$$v},expression:"limit"}}),_c('a',{staticClass:"tw-whitespace-nowrap tw-mr-6 tw-font-semibold",class:{
            'tw-text-neutral-300 tw-cursor-default': _vm.earlierDisabled,
          },on:{"click":_vm.earlier}},[_c('i',{staticClass:"fa fa-angle-double-left",attrs:{"aria-hidden":"true"}}),_vm._v(" Previous")]),_c('a',{staticClass:"tw-whitespace-nowrap tw-font-semibold",class:{
            'tw-text-neutral-300 tw-cursor-default': _vm.laterDisabled,
          },on:{"click":_vm.later}},[_vm._v("Next "),_c('i',{staticClass:"fa fa-angle-double-right",attrs:{"aria-hidden":"true"}})])],1)])]),_c('ui-data-table',{attrs:{"headers":_vm.headers,"isLoading":!!_vm.isLoading,"items":_vm.systemJobsData,"noDataMessage":"There are no jobs to display.","hideable-columns":"","resizeable-columns":""},scopedSlots:_vm._u([{key:"td-jobId",fn:function(ref){
          var job = ref.item;
return [_c('td',{},[_c('div',{staticClass:"tw-whitespace-nowrap tw-flex tw-justify-between tw-items-center"},[_vm._v(" "+_vm._s(job.id)+" ")])])]}},{key:"td-name",fn:function(ref){
          var job = ref.item;
return [_c('td',{},[_c('div',{staticClass:"tw-whitespace-nowrap tw-flex tw-justify-between tw-items-center"},[_vm._v(" "+_vm._s(job.name)+" ")])])]}},{key:"td-status",fn:function(ref){
          var job = ref.item;
return [_c('td',{staticClass:"tw-text-center tw-w-0"},[_c('div',{staticClass:"tw-whitespace-nowrap tw-flex tw-justify-between tw-items-center"},[(job.status === 'succeeded')?_c('ui-tooltip',{staticClass:"tw-align-middle tw-ml-2",attrs:{"text":"Succeeded","position":"bottom"}},[_c('fa',{staticClass:"tw-h-4 tw-w-5 tw-py-0.5 tw-mr-4 tw-text-green-500 tw-text-success-300 tw-cursor-pointer",attrs:{"icon":"fa-solid fa-circle-check"}})],1):_vm._e(),(job.status === 'failed')?_c('ui-tooltip',{staticClass:"tw-align-middle tw-ml-2",attrs:{"text":"Failed","position":"bottom"}},[_c('fa',{staticClass:"tw-h-4 tw-w-5 tw-py-0.5 tw-mr-4 tw-text-red-500 tw-text-failure-300 tw-cursor-pointer",attrs:{"icon":"fa-solid fa-circle-xmark"}})],1):_vm._e(),(job.status === 'cancelled')?_c('ui-tooltip',{staticClass:"tw-align-middle tw-ml-2",attrs:{"text":"Cancelled","position":"bottom"}},[_c('fa',{staticClass:"tw-h-4 tw-w-5 tw-py-0.5 tw-mr-4 tw-text-red-500 tw-text-failure-300 tw-cursor-pointer",attrs:{"icon":"fa-solid fa-ban"}})],1):_vm._e(),(job.status === 'succeededWithWarnings')?_c('ui-tooltip',{staticClass:"tw-align-middle tw-ml-2",attrs:{"text":"Succeeded with warnings","position":"bottom"}},[_c('fa',{staticClass:"tw-h-4 tw-w-5 tw-py-0.5 tw-mr-4 tw-text-yellow-500 tw-text-warning-300 tw-cursor-pointer",attrs:{"icon":"fa-solid fa-triangle-exclamation"}})],1):_vm._e(),(job.status === 'running')?_c('ui-tooltip',{staticClass:"tw-align-middle tw-ml-2",attrs:{"text":"Running","position":"bottom"}},[_c('ui-loading',{staticClass:"tw-text-xl tw-h-4 tw-align-top"})],1):_vm._e()],1)])]}},{key:"td-createdBy",fn:function(ref){
          var job = ref.item;
return [_c('td',{},[_c('div',{staticClass:"tw-whitespace-nowrap tw-flex tw-justify-between tw-items-center"},[_vm._v(" "+_vm._s(job.createdByUser.name)+" ")])])]}},{key:"td-createdSEC",fn:function(ref){
          var job = ref.item;
return [_c('td',{},[_c('div',{staticClass:"tw-whitespace-nowrap tw-flex tw-justify-between tw-items-center"},[_vm._v(" "+_vm._s(new Date(job.createdSEC * 1000).toLocaleString(undefined, { timeZoneName: 'short' }))+" ")])])]}},{key:"td-params",fn:function(ref){
          var job = ref.item;
return [_c('td',{},_vm._l((job.params),function(param){return _c('div',{key:param.name},[(param.name === 'walletId')?_c('div',{staticClass:"tw-whitespace-nowrap tw-flex tw-justify-between tw-items-center"},[_vm._v(" Wallet: "+_vm._s(_vm.getWalletName(param.value))+" ")]):_vm._e(),(param.name === 'startSEC')?_c('div',{staticClass:"tw-whitespace-nowrap tw-flex tw-justify-between tw-items-center"},[_vm._v(" Start Date: "+_vm._s(new Date(param.value * 1000).toLocaleString(undefined, { timeZoneName: 'short' }))+" ")]):_vm._e(),(param.name === 'endSEC')?_c('div',{staticClass:"tw-whitespace-nowrap tw-flex tw-justify-between tw-items-center"},[_vm._v(" End Date: "+_vm._s(new Date(param.value * 1000).toLocaleString(undefined, { timeZoneName: 'short' }))+" ")]):_vm._e()])}),0)]}},{key:"td-startedSEC",fn:function(ref){
          var job = ref.item;
return [_c('td',{},[(job.startedSEC)?_c('div',{staticClass:"tw-whitespace-nowrap tw-flex tw-justify-between tw-items-center"},[_vm._v(" "+_vm._s(new Date(job.startedSEC * 1000).toLocaleString(undefined, { timeZoneName: 'short' }))+" ")]):_vm._e()])]}},{key:"td-completedSEC",fn:function(ref){
          var job = ref.item;
return [_c('td',{},[(job.completedSEC)?_c('div',{staticClass:"tw-whitespace-nowrap tw-flex tw-justify-between tw-items-center"},[_vm._v(" "+_vm._s(new Date(job.completedSEC * 1000).toLocaleString(undefined, { timeZoneName: 'short' }))+" ")]):_vm._e()])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }